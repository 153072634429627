import { Helmet } from "react-helmet-async";
import PageHero from "../../components/PageHero";
import { termsData } from "./terms";

const TermsAndConditions = () => {
  return (
    <main className="mb-80">
      <Helmet prioritizeSeoTags>
        <title>Terms and Conditions</title>
        <meta
          name="description"
          content="Review Asapuu's Terms and Conditions. Understand the terms of use, payment policies, intellectual property rights, and dispute resolution procedures."
        />
        <meta property="og:title" content="Terms and Conditions" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.asapuu.com/terms-and-conditions"
        />
        <meta
          property="og:description"
          content="Review Asapuu's Terms and Conditions. Understand the terms of use, payment policies, intellectual property rights, and dispute resolution procedures."
        />
        <link
          rel="canonical"
          href="https://www.asapuu.com/terms-and-conditions"
        />
      </Helmet>
      <PageHero
        title="Terms and Conditions"
        info="Last updated October 6th, 2024."
      />
      <section className="container mx-auto px-5 flex flex-col gap-5 my-20 text-lg">
        {termsData.map((section, index) => (
          <div key={index}>
            <h2 className="text-primary">{section.title}</h2>
            {section.content.map((paragraph, idx) => (
              <p key={idx} className="text-sm">
                {paragraph}
              </p>
            ))}
          </div>
        ))}
      </section>
    </main>
  );
};

export default TermsAndConditions;
