import { Helmet } from "react-helmet-async";
import PageHero from "../../components/PageHero";

const About = () => {
  return (
    <main className=" mb-80">
      <Helmet prioritizeSeoTags>
        <title>About Us</title>
        <meta
          name="description"
          content="Your reliable and one-stop Platform for converting all your gift
          cards, digital assets such as Bitcoin, USDT and other digital assets
          to NAIRA."
        />
        <meta property="og:title" content="About Us" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.asapuu.com/about" />
        <meta
          property="og:description"
          content="Your reliable and one-stop Platform for converting all your gift
          cards, digital assets such as Bitcoin, USDT and other digital assets
          to NAIRA."
        />
        <link rel="canonical" href="https://www.asapuu.com/about" />
      </Helmet>
      <PageHero
        title="About Us"
        info="Learn about the team behind ASAPUU and our journey till date"
      />
      <section className="container mx-auto px-5 flex flex-col gap-10 my-20 text-lg">
        <p>
          Asapuu is the real deal when it comes to a secured, fast paying and
          hitch-free platform for your day to day exchange of Digital assets and
          gift cards to cash.
        </p>
        <p>
          Your reliable and one-stop Platform for converting all your gift
          cards, digital assets such as Bitcoin, USDT and other digital assets
          to NAIRA. With our state of the Art trading and cash out system, we
          ensure a seamless transaction for all our clients alongside our 24
          hours customer service.
        </p>
        With our years of experience, we have traded millions of naira,
        hitch-free and at amazing rates with thousands of happy clients who are
        always eager to come back for more as we are not just reliable but we
        operate on a transparent exchange platform in Nigeria.
        <p>
          With our zeal to do more and provide more utilities, it is without a
          doubt that we can boldly say that “with ASAPUU, you have got the best
          exchange platform for all your gift cards, Bitcoin and digital assets
          transaction, just at your fingertip.
        </p>
      </section>
    </main>
  );
};

export default About;
