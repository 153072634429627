import { Helmet } from "react-helmet-async";
import PageHero from "../../components/PageHero";

const AccountDeletion = () => {
  return (
    <main className="mb-80">
      <Helmet prioritizeSeoTags>
        <title>Account Deletion</title>
        <meta
          name="description"
          content="Learn how to delete your Asapuu account. Follow the steps to remove your account securely on the app you created it."
        />
        <meta property="og:title" content="Account Deletion" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.asapuu.com/account-deletion"
        />
        <meta
          property="og:description"
          content="Learn how to delete your Asapuu account. Follow the steps to remove your account securely on the app you created it."
        />
        <link rel="canonical" href="https://www.asapuu.com/account-deletion" />
      </Helmet>
      <PageHero title="Account Deletion" info="" />
      <section className="container mx-auto px-5 flex flex-col gap-5 my-20 text-lg">
        <div>
          <h2 className="text-primary">How to Delete Your Account</h2>
          <p className="text-sm">
            To delete your Asapuu account, please follow these steps:
          </p>
          <ol className="list-decimal list-inside text-sm">
            <li>Open the Asapuu app on your mobile device.</li>
            <li>Navigate to the "Settings" section in the app.</li>
            <li>
              Choose "Delete Account" and follow the on-screen instructions.
            </li>
            <li>
              Confirm your decision to delete the account. Please note that this
              action is irreversible.
            </li>
          </ol>
        </div>
        <div>
          <h2 className="text-primary">Important Notes</h2>
          <p className="text-sm">
            Account deletion is only available through the app where the account
            was created. If you have any questions or encounter difficulties
            during the process, please contact our support team via the app or
            email us at support@asapuu.com.
          </p>
        </div>
      </section>
    </main>
  );
};

export default AccountDeletion;
